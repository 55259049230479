<template>
  <div class="flex-column" style="height: 100vh;">
    <van-nav-bar title="医生详情" left-text="返回" @click-left="back" left-arrow />
    <van-pull-refresh style="overflow: auto;" class="flex1" v-model="isLoading" @refresh="loadData">
      <!-- 主体 -->
      <div class="login_form" >
        <van-cell class="doctor-info" style="background:#20a0ff;">
          <template #title>
            <div class="flex-row">
              <div>
                <van-image :src="doctor.avatar" class="doctor-avatar-image" />
              </div>
              <div
                class="flex1 flex-column justify-around"
                style="padding: 8px;margin-left: 4px;color: white;"
              >
                <p class="bold" style="font-size:1.4em;">{{doctor.name}}</p>
                <p style="font-size:1.1em;">{{doctor.title}}</p>
              </div>
            </div>
          </template>
        </van-cell>
        <div class="bg-fff doctor-goodat">
          <span class="bold">擅长领域：</span>
          {{doctor.goodAt && doctor.goodAt.trim()}}
        </div>
        <van-grid direction="horizontal" :column-num="3" style="margin-top:7px;">
          <van-grid-item text=" " />
          <van-grid-item text="上午" />
          <van-grid-item text="下午" />
        </van-grid>
        <!--暂无排班-->
        <van-empty
          v-if="numList.length == 0"
          :image="require('../../assets/img/calendar.png')"
          description="暂无排班"
          style="width:100%height:100%;background:#fff;"
        />
        <div v-if="numList.length > 0">
          <van-grid v-for="(item,index) in numList" :key="index" :column-num="3">
            <van-grid-item>
              <template #text>
                <div style="color:#888;">{{item.weekDay}}</div>
                <div style="color:#888;margin-top:5px;font-size:12px;">{{item.monthDay}}</div>
              </template>
            </van-grid-item>
            <van-grid-item>
              <van-tag
                v-if="item.amAllCount>0 || item.amHasOnSite"
                @click="showReserve(item, 'am')"
                :type=" item.amAllCount > item.amUsed ? 'success'                             : ( !item.amHasOnSite ? 'default' : 'primary' ) "
                style="padding:8px;"
              >{{       item.amAllCount > item.amUsed ? '余:'+(item.amAllCount - item.amUsed) : ( !item.amHasOnSite ? '已约满'   : '现场挂号' ) }}</van-tag>
            </van-grid-item>
            <van-grid-item>
              <van-tag
                v-if="item.pmAllCount>0 || item.pmHasOnSite"
                @click="showReserve(item, 'pm')"
                :type=" item.pmAllCount > item.pmUsed ? 'success'                             : ( !item.pmHasOnSite ? 'default' : 'primary' ) "
                style="padding:8px;"
              >{{       item.pmAllCount > item.pmUsed ? '余:'+(item.pmAllCount - item.pmUsed) : ( !item.pmHasOnSite ? '已约满'   : '现场挂号' ) }}</van-tag>
            </van-grid-item>
          </van-grid>
        </div>

        <div style="background:#fff; height:160px;"></div>
        <!-- <van-button class="logout" @click="logout" block round type="danger" >退出登录</van-button> -->
      </div>
    </van-pull-refresh>
    <van-popup v-model="show" style="width:360px;height:200px;background:#dddddd;">
      <div class="flex-row">
        <div style="width:80px;height:200px;font-size:18px;background:#fff;margin-right: 2px;">
          <ul class="list_select">
            <li
              v-for="(item,index) in typeList"
              :class="type == item.type?'active':''"
              :key="item.type"
              @click="setCurrent(index)"
            >{{item.name}}</li>
          </ul>
        </div>
        <div
          style="flex: 1;width: 10px;background:#fff;width:238px;height:200px;padding-top:5px;padding-bottom:5px;overflow:scroll; "
        >
          <div v-for="item in timeList" :key="item.id" class="timeLine flex-row flex-center">
            <div>{{item.startTime}}~{{item.endTime}}</div>
            <div class="flex1" style="padding: 0 14px;text-align: right;">{{ item.openType === 2 ? '' : '￥'+ (item.fee || 0) }}</div>
            <div v-if="item.openType === 2" style="width: 80px;text-align: center;">现场挂号</div>
            <div v-if="item.openType !== 2">
              <van-tag
                round
                style="height:24px;padding: 0;line-height: 24px;width: 80px;"
                @click="startReserve(item)"
                :type=" item.openType === 2 ? '' : (item.usedCount >= item.openCount ? 'default':'success')"
              >{{ item.openType === 2 ? '现场挂号' : (item.usedCount >= item.openCount ? '已约满':'余: '+(item.openCount - item.usedCount))}}</van-tag>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import { mapGetters } from "vuex";
export default {
  components: {
    Footer,
  },

  computed: {
    ...mapGetters(["userId", "isLogin"]),
  },

  data() {
    return {
      type: null,
      typeList: [],
      voilateTimes: null,
      reserveDate: null,
      timeList: [],
      allTimeList: [],
      activeKey: 0,
      show: false,
      numList: [],
      doctor: {
        avatar: "",
        name: "张三",
        title: "主任医师",
        goodAt: "各种疑难杂症",
      },
      isLoading: false,
    };
  },

  methods: {
    startReserve(item) {
      if (item.openType === 2 || item.usedCount >= item.openCount) {
        return;
      }
      sessionStorage.time = JSON.stringify(item);
      sessionStorage.reserveDate =
        this.reserveDate + " " + item.startTime + "~" + item.endTime;
      this.$router.push("/reserve?id=" + item.id);
    },
    setCurrent(index) {
      console.log(index);
      var type = this.typeList[index].type;
      this.type = type;
      this.timeList = this.allTimeList.filter(function (a) {
        return a.type == type;
      });
    },
    onTypeChange(index) {
      console.log(index);
      var type = this.typeList[index].type;
      this.timeList = this.allTimeList.filter(function (a) {
        return a.type == type;
      });
    },
    showReserve(item, halfTime) {
      //检查是否达到最大违约
      if (
        this.voilateTimes.voilateTimes > 0 &&
        this.voilateTimes.leftTimes == 0
      ) {
        this.$dialog
          .alert({
            closeOnPopstate: true,
            message:
              "违约次数已达" + this.voilateTimes.voilateTimes + "次,无法预约",
          })
          .then(() => {
            // on close
          });
        return;
      }

      //  item.pmAllCount > item.pmUsed ? '余:'+(item.pmAllCount - item.pmUsed) : ( !item.pmHasOnSite ? '已约满'   : '现场挂号' )
      if (halfTime == "am") {
        if (!item.amHasOnSite && item.amAllCount == item.amUsed) {
          return;
        }
      } else {
        if (!item.pmHasOnSite && item.pmAllCount == item.pmUsed) {
          return;
        }
      }

      this.show = true;
      this.reserveDate =
        item.arrangement.arrangeDate +
        " " +
        (halfTime == "am" ? "上午" : "下午");
      //获取数据
      this.$axios
        .get("/doctor/getDoctorDayArrangeTime", {
          params: {
            doctorId: this.$route.query.id,
            arrangeId: item.arrangement.id,
            halfTime: halfTime,
          },
        })
        .then((res) => {
          this.allTimeList = res.data;
          this.typeList = [];
          //判断开放的预约类型，对应显示
          this.allTimeList.forEach((t) => {
            var name = "";
            if (t.type == 0) {
              this.hasNormal = 1;
              name = "普通";
            } else if (t.type == 1) {
              this.hasProfession = 1;
              name = "专家";
            } else if (t.type == 2) {
              this.hasCream = 1;
              name = "膏方";
            }
            var has = false;
            for (var i = 0; i < this.typeList.length; i++) {
              var inner = this.typeList[i];
              if (inner.type == t.type) {
                has = true;
                break;
              }
            }
            if (!has) {
              this.typeList.push({ name: name, type: t.type });
            }
          });
          var that = this;
          this.timeList = this.allTimeList.filter(function (a) {
            return a.type == that.typeList[0].type;
          });
          //设置默认的类型
          if (that.typeList.length > 0) {
            that.type = that.typeList[0].type;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getDoctorArrangeTime() {
      this.$axios
        .get("/doctor/getDoctorArrangeTime", {
          params: { doctorId: this.$route.query.id },
        })
        .then((res) => {
          this.isLoading = false;
          this.doctor = res.data.doctor;
          sessionStorage.doctor = JSON.stringify(this.doctor);
          this.numList = res.data.arrangement;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    back() {
      this.$back();
    },
    getVoilateTimes() {
      this.$axios
        .get("/wuser-reserve-times/getMy", {
          params: { token: this.$user.openId },
        })
        .then((res) => {
          this.voilateTimes = res.data;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    loadData() {
      this.getDoctorArrangeTime();
      this.getVoilateTimes();
    },
  },
  create() {},

  mounted() {
    this.getDoctorArrangeTime();
    this.getVoilateTimes();
  },
};
</script>
<style>
.list_select {
  font-size: 16px;
}
.list_select li {
  padding-top: 12px;
  padding-bottom: 12px;
  margin: 0 auto;
  text-align: center;
}
.active {
  background: #eeeeee;
  color: #ff0000;
}
</style>
<style lang="less" scoped>
.van-tag {
  font-size: 14px;
  width: 74px;
  height: 36px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
/* 本页公共样式 */
.gray {
  color: #797d82;
}

.bott0 {
  bottom: 0;
}

.orange {
  color: #ff5f16;
}

.yellow {
  color: #ffb232;
}

.bd-gray {
  border-bottom: 1px solid #f5f5f5;
}

// 头部图片
.beijin {
  background: #fff;
  height: 200px;
  background-size: cover;
  margin-top: -44px;
}

// 用户名和头像
.users {
  top: 55px;
  left: 22px;
  position: absolute;

  // 头像
  .imege {
    height: 65px;
    border-radius: 50%;
  }

  .fff {
    font-size: 22px;
  }
}
// 头像
.imege {
  height: 65px;
  border-radius: 50%;
}

// 导航
.nav {
  height: 75px;

  .iconfont {
    font-weight: bold;
  }

  .cit {
    height: 26px;
  }
}

.logout {
  width: 80%;
  margin: 50px auto 0;
}
.timeLine {
  line-height: 36px;
  font-size: 14px;
  padding: 5px 14px;
}
.timeLine span {
  padding: 0 6px 0 6px;
}
// 主体
main {
  .list {
    height: 50px;

    .image {
      height: 20px;
    }
  }

  .foun {
    font-size: 12px;
  }
}

.login_form {
  background: #eaeaea;
}

/* 底部 */
.footer {
  height: 50px;
}
.item_container {
  width: 100%;
  overflow: hidden;
}
.item_container span {
  display: block;
  float: left;
}
</style>